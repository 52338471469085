import * as React from "react"
import { useRef } from "react"
import { PageProps, graphql } from "gatsby"

import Seo from "../../../components/seo"
import Layout from "../../../components/layout"
import Box from "../../../components/box"
import Text from "../../../components/text"
import Divider from "../../../components/divider"
import IntroPoint from "../../../components/resume/introPoint"
import Illustration from "../../../components/resume/illustration"
import CoverProjectOverview from "../../../components/resume/coverProjectOverview"
import ResearchSidebar from "../../../components/resume/reasearchSidebar"
import CardsGallery from "../../../components/resume/cardsGallery"
import {
  BackLink,
  Section,
  SectionContent,
  SectionTitle,
} from "../../../components/resume/structural"

import { ProjectOverviewDataProps } from "../../../components/resume/types"

const SosProjectOverviewPage: React.FC<PageProps<ProjectOverviewDataProps>> = ({
  data,
}) => {
  const siteTitle = data.site.siteMetadata.title

  const { cards2, cards3, cards4 } = data
  const cardsIllustrations = [cards2, cards3, cards4]

  const layout = useRef<HTMLLIElement>(null)
  const cards = useRef<HTMLLIElement>(null)
  const supply = useRef<HTMLLIElement>(null)

  return (
    <Layout title={siteTitle} width="960px">
      <Seo title="StarOfService: Project Overview page" />
      <Box
        css={{
          width: "$full",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "$64",

          h2: {
            marginBottom: "$32",
          },
        }}
      >
        <Box
          flex="column"
          css={{
            minHeight: "$fullMinusHeader",
            justifyContent: "end",
            boxSizing: "border-box",
            gap: "$24",
          }}
        >
          <CoverProjectOverview cover={data.cover} />
          <Box
            grid="1to3"
            css={{
              alignItems: "baseline",
            }}
          >
            <Box></Box>
            <Box
              as="h1"
              flex={"column"}
              css={{
                marginY: "0",
                gap: "$16",
                width: "$full",
                fontSize: "$48",
                lineHeight: "1.2",
              }}
            >
              Project Overview page redesign
              <Text css={{ color: "$text_tertiary" }}>StarOfService, 2023</Text>
            </Box>
          </Box>
          <IntroPoint
            large
            title="Problem"
            text="After the business model switch, the Project Overview page became a part of the primary client funnel. Usability tests and metrics performance highlighted room for growth."
          />
          <IntroPoint
            large
            title="Role"
            text="Ideated, researched, designed, and implemented experiments to redesign the user journey and increase page conversion."
          />
          <Box>
            <IntroPoint
              large
              title="Goal and results"
              text={`The main goal was to increase client engagement with professionals on this page.`}
            />
            <IntroPoint
              large
              title=""
              text="Over about 6 months of iterations, the share of Clients starting communication with Professionals increased almost 4 times. On the scale of the entire funnel it can be estimated as x2.2 growth."
            />
            <Box
              grid="1to3"
              css={{
                alignItems: "baseline",
              }}
            >
              <Box></Box>
              <Box
                css={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: "$16",
                  rowGap: "$16",

                  "@bp2": {
                    gridTemplateColumns: "1fr",
                  },
                }}
              >
                <IntroPoint
                  boxed
                  large
                  title="Target page conversion"
                  text="x4 increase"
                />
                <IntroPoint
                  boxed
                  large
                  title="Entire funnel covertion"
                  text="x2.2 increase"
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box>
          <Divider direction="horisontal" />
          <Section>
            <SectionTitle title="Background" />
            <SectionContent>
              <Illustration
                image={data.initialState}
                alt="Project overview page placed on simplified version of a Customer Journey Map"
                caption="Project overview page as a part of clients' journey"
              />
            </SectionContent>
            <SectionContent>
              StarOfService is a platform connecting clients with skilled
              professionals for various service needs. The Project Overview page
              plays a critical role in this journey, as it's where clients
              encounter potential matches for their requests. It functioned
              adequately, but usability tests and metrics performance
              highlighted the optimisation needed to maximise client engagement.
            </SectionContent>
            <SectionContent>
              Within the Growth Track, I led the redesign of the Project
              Overview page to enhance user experience and increase client
              engagement with professionals.
            </SectionContent>
            <SectionContent>
              This case study details key initiatives to illustrate the
              approach:
            </SectionContent>
            <SectionContent>
              <Box
                as="ul"
                flex="column"
                css={{
                  gap: "$8",
                  marginTop: "$16",
                  paddingLeft: 0,
                  transition: "$inOut1",
                  li: {
                    listStyleType: "none",
                    color: "$text_tertiary",
                    cursor: "pointer",
                    padding: "$8 $16",
                    border: "1px solid $background_secondary",
                    borderRadius: "$16",

                    p: {
                      marginBottom: "$4",
                    },

                    "&:hover": {
                      backgroundColor: "$background_secondary",
                      border: "1px solid $background_tertiary",
                    },
                  },
                }}
              >
                <li
                  onClick={() => {
                    if (cards.current) {
                      cards.current.scrollIntoView({ behavior: "smooth" })
                    }
                  }}
                >
                  <Text
                    css={{
                      fontFamily: "$sans",
                      fontWeight: "$550",
                      fontSize: "$18",
                      textDecoration: "underline",
                    }}
                  >
                    Professional cards
                  </Text>
                  <Text size="small">Understanding client's needs</Text>
                </li>
                <li
                  onClick={() => {
                    if (layout.current) {
                      layout.current.scrollIntoView({ behavior: "smooth" })
                    }
                  }}
                >
                  <Text
                    css={{
                      fontFamily: "$sans",
                      fontWeight: "$550",
                      fontSize: "$18",
                      textDecoration: "underline",
                    }}
                  >
                    Architecture transformations
                  </Text>
                  <Text size="small">Finding common ground</Text>
                </li>
                <li
                  onClick={() => {
                    if (supply.current) {
                      supply.current.scrollIntoView({ behavior: "smooth" })
                    }
                  }}
                >
                  <Text
                    css={{
                      fontFamily: "$sans",
                      fontWeight: "$550",
                      fontSize: "$18",
                      textDecoration: "underline",
                    }}
                  >
                    Open supply
                  </Text>
                  <Text size="small">Design without design</Text>
                </li>
              </Box>
            </SectionContent>
          </Section>

          <SectionContent>
            <Divider direction="horisontal" />
          </SectionContent>

          <Section ref={cards}>
            <SectionTitle
              title="Professional cards"
              subtitle="Understanding client’s needs"
              sidebar="#1"
            />

            <Box
              flex="column"
              css={{
                gap: "$16",
              }}
            >
              <IntroPoint
                large
                title="Problem"
                text={`The professional card is a crucial element on the page. By that moment, we used to have several views from different models from different ages — it was hard to track why some of those decisions were made and how to make it "sell" professionals to clients.`}
              />
              <SectionContent>
                <Illustration
                  image={data.cardsInitial}
                  alt="Initial version of cards: they contained very little information with a lot of promiment elements."
                  caption="Initial version of professianal cards"
                />
              </SectionContent>
              <SectionContent
                sidebar={
                  <Box as="p" grid="itemLeft">
                    Process
                  </Box>
                }
              >
                <Box
                  flex={"column"}
                  css={{
                    gap: "$16",
                  }}
                >
                  <Box>
                    While we had some other hypotheses to test, I initiated a
                    series of user interviews to come prepared for iterating on
                    cards. The focus was on:
                    <ul>
                      <li>Finding out the previous hiring experience</li>
                      <li>
                        How different profile elements affect the process
                        comparison of professionals
                      </li>
                    </ul>
                  </Box>
                  <ResearchSidebar
                    recommendationIl={
                      <Illustration
                        image={data.cardRecomendationExample}
                        alt="Recommendations on how to present new StarOfService users"
                        caption="Example of recommendations"
                      />
                    }
                    cardsIl={
                      <Illustration
                        image={data.procards}
                        alt="15 miniature pairs of Pro cards"
                        caption="Pro cards variants prepared for user interview"
                      />
                    }
                    cover={data.procards}
                  />
                  <Text size={"large"}>
                    With all that I'd set to cards redesign and launched a
                    series of AB tests to tailor the application of the
                    findings.
                  </Text>
                </Box>
              </SectionContent>
              <IntroPoint
                large
                title="Results"
                text="We've ended up with several variations tailored per type of professional or stage of interactions (contacted or not, applying or not, 'bookable online', etc). 
                "
              />
              <CardsGallery
                cards={cardsIllustrations}
                largeImage={data.cardsUpdated}
              />
              <IntroPoint
                large
                title=""
                text="It resulted in increased client engagements with professionals
                and better usability tests feedback."
              />
              <SectionContent>
                <Box
                  css={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    columnGap: "$8",
                    rowGap: "$16",

                    "@bp1": {
                      gridTemplateColumns: "1fr",
                    },
                  }}
                >
                  <IntroPoint
                    boxed
                    large
                    title="Target conversion on mobile"
                    text="~40% increase "
                  />
                  <IntroPoint
                    boxed
                    large
                    title="Target conversion on desktop"
                    text="~25% increase "
                  />
                </Box>
              </SectionContent>
            </Box>
          </Section>

          <SectionContent>
            <Divider direction="horisontal" />
          </SectionContent>

          {/* Architecture */}

          <Section ref={layout}>
            <SectionTitle
              title="Architecture transformations"
              subtitle="Finding common ground"
              sidebar="#2"
            />
            <SectionContent
              sidebar={
                <Box as="p" grid="itemLeft">
                  Problem
                </Box>
              }
            >
              <Text size="large">
                The initial Project Overview layout presented challenges for
                clients during the professional selection process. Usability
                interviews and user recordings revealed difficulties in:
                <Box
                  flex={"column"}
                  css={{
                    gap: "$16",
                    paddingY: "$24",
                  }}
                >
                  <IntroPoint
                    boxed
                    outlined
                    reverse
                    text="Distinguishing contacted professionals"
                    title={`The overall layout felt cluttered and overwhelming, hindering clear decision-making.`}
                  />
                  <IntroPoint
                    boxed
                    outlined
                    reverse
                    text="Information overload"
                    title={`A single list of all professionals with more distinctive
                    cards, clearly divided by the level of interaction.`}
                  />
                  <Illustration
                    image={data.projectOverviewInitial}
                    alt="Initial version of the page: The page is a list of all professionals somehow related to the request slightly divided into groups."
                  />
                </Box>
              </Text>
            </SectionContent>
            <SectionContent
              sidebar={
                <Box as="p" grid="itemLeft">
                  Process
                </Box>
              }
            >
              <Text size="large">
                To address these issues, the CTO and I developed two contrasting
                hypotheses for improving the layout:
                <Box
                  flex={"column"}
                  css={{
                    gap: "$16",
                    paddingY: "$24",
                  }}
                >
                  <IntroPoint
                    boxed
                    outlined
                    reverse
                    text="CTO's"
                    title={`Maintain the single-page structure but enhance the distinctiveness of professional groups based on interaction level (contacted, applied, etc.)`}
                  />
                  <IntroPoint boxed outlined reverse text="Mine">
                    Divide the list of professionals by purpose:
                    <Box
                      as={"ul"}
                      css={{
                        marginBottom: "0",
                        li: {
                          fontSize: "$16",
                          color: "$text_secondary",
                        },
                      }}
                    >
                      <Box as="li" css={{ marginBottom: "$16" }}>
                        The "Project space" screen dedicated to communication,
                        showcasing professionals the client has already
                        messaged.
                      </Box>
                      <Box as="li" css={{ marginBottom: "0" }}>
                        The "Find professionals" screen for, evidently, finding
                        professionals, displaying all other relevant
                        professionals
                      </Box>
                    </Box>
                  </IntroPoint>
                </Box>
                Through iterating and analysing results in various scenarios, we
                arrived at a hybrid solution. This combined the strengths of
                both hypotheses and addressed user needs more effectively:
                <Box
                  flex={"column"}
                  css={{
                    gap: "$16",
                    paddingY: "$24",
                  }}
                >
                  <IntroPoint boxed outlined reverse text="">
                    Introduction of Tabs:
                    <Box
                      as={"ul"}
                      css={{
                        marginBottom: "0",
                        li: {
                          fontSize: "$16",
                          color: "$text_secondary",
                        },
                      }}
                    >
                      <Box as="li" css={{ marginBottom: "$16" }}>
                        "Inbox": Clearly displays contacted professionals and
                        those who applied to the request.
                      </Box>
                      <Box as="li" css={{ marginBottom: "0" }}>
                        "All professionals": Lists all other relevant
                        professionals corresponding to the client's request.
                      </Box>
                    </Box>
                  </IntroPoint>
                  <IntroPoint
                    boxed
                    outlined
                    reverse
                    text=""
                    title={`Introduction of redirection and display conditions to treat different use cases in a more efficient manner.`}
                  />
                  <Illustration
                    image={data.architecture}
                    alt="Page structure scheme: after request creation, if the client has incoming quotes they appears on Inbox tab, otherwise is redirected to All professional tab."
                    caption="Rough final page structure"
                  />
                </Box>
              </Text>
            </SectionContent>
            <SectionContent
              sidebar={
                <Box as="p" grid="itemLeft">
                  Results
                </Box>
              }
            >
              <Text size="large">
                This hybrid layout provided a clearer distinction between
                communication stages and improved information organisation,
                ultimately optimising the user experience for client
                decision-making.
              </Text>

              <Illustration
                image={data.projectOverviewAfter}
                alt="A pop-up with a pre-filled message ready to be sent"
                caption="We started to use a pop-up for initial message instead of redirection to the chat page"
              />
            </SectionContent>

            <IntroPoint
              large
              title=""
              text="It resulted in increased client engagements with professionals
                and better usability tests feedback."
            />
            <SectionContent>
              <Box
                css={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: "$8",
                  rowGap: "$16",

                  "@bp1": {
                    gridTemplateColumns: "1fr",
                  },
                }}
              >
                <IntroPoint
                  boxed
                  large
                  title="Conversion to open profile"
                  text="85% increase "
                />
                <IntroPoint
                  boxed
                  large
                  title="Conversations with pros started"
                  text="70% increase "
                />
              </Box>
            </SectionContent>
          </Section>

          <SectionContent>
            <Divider direction="horisontal" />
          </SectionContent>

          {/* open supply */}

          <Section ref={supply}>
            <SectionTitle
              title={`"Open supply"`}
              subtitle="Design without design"
              sidebar="#3"
            />

            <IntroPoint
              large
              title="Problem"
              text={`StarOfService encountered a challenge with limited supply. Requests for unpopular services in smaller locations often yielded no results, creating a dead end for clients and hindering the user flow.`}
            />
            <SectionContent
              sidebar={
                <Box as="p" grid="itemLeft">
                  Process
                </Box>
              }
            >
              <Text size="large">
                Our first attempt involved expanding the search geography.
                However, this was poorly received by users and created a
                foundation of mistakes — clients were booking appointments with
                professionals from other cities without noticing. So, the
                problem required an other solution.
              </Text>
              <Illustration
                image={data.review}
                alt={`Not complimentary customer review, who complains on 100km away professionals`}
                caption="Example of customer review"
              />
              <Text size="large">
                At that moment, interactions with the client required a
                subscription, and there were enough professionals without one.
                So, we decided to start displaying them to our clients in case
                we lack subscribed professionals. The initiative was called
                "Open supply". Usually, their profiles were less compelling and
                fulfilled, but this approach appeared more efficient than
                loosening the search query.
              </Text>
              <Illustration
                image={data.openSupply}
                alt={`2 "Open Supply" professionals and simplified notification about clients' interest`}
                caption="Clients get access to more professionals, while we can use
                  clients' interest to re-engage more professionals"
              />
              <Text size="large">
                Also, it allowed us to use the client's interest as leverage to
                attract more professionals into the subscription by having a
                real demand instead of promises of thriving.
              </Text>
              <Text size="large">
                To decrease the chances of getting no answer, we encouraged
                clients to contact more professionals. Apart from cheering
                hints, we introduced a popup with a placeholder message (or
                repeating the last sent), allowing clients to declare their
                interest in professional services in just 2 clicks. And it
                helped by doubling the number of clients contacting more than
                one professional.
              </Text>
              <Illustration
                image={data.contactPopup}
                alt="A pop-up with a pre-filled message ready to be sent"
                caption="We started to use a pop-up for initial message instead of redirection to the chat page"
              />
              <Text size="large">
                The next step, which is the introduction of bulk messaging, was
                postponed due to switching to the other mission.
              </Text>
            </SectionContent>
            <IntroPoint
              large
              title="Results"
              text={`Open Supply successfully "unclogged" a significant portion of client requests and led to a noticeable increase in Project Overview conversion. While not all Open Supply professionals transitioned to subscriptions, this approach opened a valuable opportunity for client-driven conversion.`}
            />
            <SectionContent>
              <Box
                css={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: "$8",
                  rowGap: "$16",

                  "@bp1": {
                    gridTemplateColumns: "1fr",
                  },
                }}
              >
                <IntroPoint
                  boxed
                  large
                  title="Conversations with pros started"
                  text="x2 increase "
                />
                <IntroPoint
                  boxed
                  large
                  title="subscriptions started after messages from clients"
                  text="~70% increase "
                />
              </Box>
            </SectionContent>
          </Section>

          <SectionContent>
            <Divider direction="horisontal" />
          </SectionContent>

          <Section>
            <SectionTitle title="Results" />
            <SectionContent>
              <Text size="large">
                For about half a year, I have been working on the Project
                Overview page as a part of the Client funnel optimisation scope.
                The redesign included:
              </Text>
              <Box
                css={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: "$8",
                  rowGap: "$8",
                  paddingBottom: "$24",

                  "@bp1": {
                    gridTemplateColumns: "1fr",
                  },
                }}
              >
                <IntroPoint boxed outlined text="Segmentation of clients" />
                <IntroPoint boxed outlined text="Page architecture rebuild" />
                <IntroPoint
                  boxed
                  outlined
                  text="Adjustment of the business model"
                />
                <IntroPoint
                  boxed
                  outlined
                  text="Optimisation of crucial elements and flows"
                />
              </Box>
              <Text size="large">
                As a result, the share of Clients starting communication with
                Professionals increased almost in 4 times. This phenomenal
                number was partially achieved by sacrificing the next funnel
                step performance, but still, on the scale of the entire funnel,
                it can be estimated as x2.2 growth.
              </Text>

              <Box
                css={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: "$8",
                  rowGap: "$16",
                  marginBottom: "$24",

                  "@bp1": {
                    gridTemplateColumns: "1fr",
                  },
                }}
              >
                <IntroPoint
                  boxed
                  large
                  title="Target page conversion"
                  text="x4 increase"
                />
                <IntroPoint
                  boxed
                  large
                  title="Entire funnel covertion"
                  text="x2.2 increase"
                />
              </Box>
              <Illustration
                image={data.resultsGraph}
                alt="Two graphs showing gradual growth of target metric"
                caption="The target metric progression: cumulative and devided into segments."
              />

              <Text size="large">
                There were still ideas for further Project Overview page
                enhancements, but it was excluded from the Growth team scope as
                we the entire Client funnel optimisation.
              </Text>
            </SectionContent>
          </Section>

          <SectionContent>
            <Divider direction="horisontal" />
          </SectionContent>

          <Section>
            <SectionContent>
              <Text size="large">Thank you.</Text>
            </SectionContent>
          </Section>
          <SectionContent>
            <BackLink />
          </SectionContent>
        </Box>
      </Box>
    </Layout>
  )
}

export default SosProjectOverviewPage

export const pageQuery = graphql`
  query {
    procards: file(relativePath: { eq: "portfolio-images/procards.png" }) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    cardRecomendationExample: file(
      relativePath: { eq: "portfolio-images/card-recomendation-example.png" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    openSupply: file(relativePath: { eq: "portfolio-images/open-supply.png" }) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    contactPopup: file(
      relativePath: { eq: "portfolio-images/contact-popup.png" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    resultsGraph: file(
      relativePath: { eq: "portfolio-images/results-graph.png" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    initialState: file(
      relativePath: { eq: "portfolio-images/initial-state.png" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    cardsExamples: file(
      relativePath: { eq: "portfolio-images/cards-examples.png" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    cardsUpdated: file(
      relativePath: { eq: "portfolio-images/cards-updated.png" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    cardsInitial: file(
      relativePath: { eq: "portfolio-images/cards-initial.png" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    architecture: file(
      relativePath: { eq: "portfolio-images/architecture.png" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    review: file(relativePath: { eq: "portfolio-images/review.png" }) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    projectOverviewInitial: file(
      relativePath: { eq: "portfolio-images/project-overview-initial.png" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    projectOverviewAfter: file(
      relativePath: { eq: "portfolio-images/project-overview-after.png" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    cards2: file(relativePath: { eq: "portfolio-images/cards_2.png" }) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    cards3: file(relativePath: { eq: "portfolio-images/cards_3.png" }) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    cards4: file(relativePath: { eq: "portfolio-images/cards_4.png" }) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    cover: file(
      relativePath: { eq: "portfolio-images/project-overview-cover.png" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1440
          placeholder: BLURRED
        )
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
