import * as React from "react"
import { ReactNode, useState } from "react"
import Box from "../box"
import Text from "../text"
import IntroPoint from "./introPoint"
import {
  GatsbyImage,
  IGatsbyImageData,
  ImageDataLike,
  getImage,
} from "gatsby-plugin-image"
import { keyframes } from "@stitches/react"
import { CloseIcon } from "../icons"
import { useMixpanel } from "gatsby-plugin-mixpanel"

const ResearchSidebar = ({
  recommendationIl,
  cardsIl,
  cover,
}: {
  recommendationIl: ReactNode
  cardsIl: ReactNode
  cover: ImageDataLike
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const mixpanel = useMixpanel()

  const popIn = keyframes({
    "0%": { opacity: "0", transform: "translateY(32px)" },
    "50%": { opacity: "1" },
    "100%": { transform: "translateY(0px)" },
  })
  return (
    <>
      <Box
        as="button"
        onClick={() => {
          setIsExpanded(true)
          mixpanel.track("Resume: Open cards research", {})
        }}
        flex="row"
        css={{
          backgroundColor: "$background_primary",
          color: "$text_secondary",
          border: "1px solid $background_secondary",
          borderRadius: "$16",
          cursor: "pointer",
          alignItems: "center",
          gap: "$16",
          padding: 0,
          width: "$full",
          textAlign: "start",
          overflow: "hidden",
          transition: "$inOut1",

          "&:hover": {
            border: "1px solid $background_tertiary",
            backgroundColor: "$background_secondary",
            "#cover-image": {
              transform: "scale(1.05)",
            },
          },
        }}
      >
        <Box
          css={{
            height: "96px",
            width: "174px",
            "@bp4": {
              height: "96px",
              width: "$128",
            },
          }}
        >
          <GatsbyImage
            id="cover-image"
            alt={""}
            style={{ width: "100%", height: "100%", gridColumn: 1, gridRow: 1 }}
            image={getImage(cover) as IGatsbyImageData}
          />
        </Box>
        <Box
          flex="column"
          css={{
            paddingY: "$8",
            justifyContent: "center",
            height: "$full",
          }}
        >
          <Text
            css={{
              fontFamily: "$sans",
              fontWeight: "$550",
              marginBottom: "$4s",
            }}
          >
            Cards interview
          </Text>
          <Text
            size="small"
            css={{
              fontFamily: "$sans",
              marginBottom: "0",
              color: "$text_tertiary",
            }}
          >
            Click to see detials
          </Text>
        </Box>
      </Box>
      {isExpanded && (
        <Box
          as="button"
          onClick={() => setIsExpanded(false)}
          css={{
            position: "fixed",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            animation: `${popIn} 0.3s cubic-bezier(.32,.7,.68,.97)`,
            top: 0,
            left: 0,
            width: "$full",
            height: "$full",
            backgroundColor: "$background_overlay",
            zIndex: 999,
            textAlign: "start",
            padding: 0,
            border: 0,
            outline: 0,

            "@bp2": {
              top: "$32",
            },
          }}
        >
          <Box
            css={{
              backgroundColor: "$background_tertiary",
              margin: "$24",
              maxWidth: "$maxW-lg",
              maxHeight: "$fullMinusHeader",
              overflow: "scroll",
              padding: "$32 $48",
              borderRadius: "$32",
              overflowX: "hidden",
              overscrollBehavior: "contain",

              "@bp2": {
                margin: 0,
                padding: "$32",
              },
            }}
          >
            <Box
              flex={"row"}
              as="h2"
              css={{
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "0",
              }}
            >
              <Text
                css={{
                  fontSize: "$24",
                  fontFamily: "$sans",
                  fontWeight: "$550",
                  marginBottom: "0",
                }}
              >
                Pro cards interview
              </Text>
              <CloseIcon size={24} />
            </Box>
            <Text size={"large"}>
              Pro card variants prepared for user interview The process was
              quite simple - 15 pairs of different variations of Pro Cards and
              then presented them to participants with recent hiring experience
              to choose the one they'd hire. Each time they were explaining the
              reasons behind their choice.
            </Text>
            {cardsIl}
            <Text size={"large"}>
              This discussion was a great starting point to dive into their
              previous experience and real-life behaviour. Usually, the first
              5-6 pairs had been more like a warmup before participants started
              to recollect the actual reasons behind their recent hirings,
              telling detailed stories and getting more open and excited about
              the whole process.
            </Text>
            <Text size={"large"}>
              So, in the end, we had:
              <Box
                css={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: "$8",
                  rowGap: "$8",
                  marginY: "$16",

                  "@bp1": {
                    gridTemplateColumns: "1fr",
                  },
                }}
              >
                <IntroPoint
                  boxed
                  outlined
                  reverse
                  text="Matrix of choices based on the card's content and layout"
                />
                <IntroPoint
                  boxed
                  outlined
                  reverse
                  text="Matrics of parameters affected the dicisions, mnoticed or neglected"
                />
                <IntroPoint
                  boxed
                  outlined
                  reverse
                  text="Witnessed participants' reflections and interactions with
                      the cards' content, patterns of Pro comparisons"
                />
                <IntroPoint
                  boxed
                  outlined
                  reverse
                  text="Received the recollections about the participants'
                      previous hiring experience and real-life behaviour and
                      decisions"
                />
              </Box>
              {recommendationIl}
            </Text>
          </Box>
        </Box>
      )}
    </>
  )
}

export default ResearchSidebar
