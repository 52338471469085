import * as React from "react"
import Box from "../box"
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"
import { PhotoType } from "./types"

const Illustration = ({
  image,
  caption,
  alt,
}: {
  image: PhotoType
  caption?: string
  alt: string
}) => {
  return (
    <Box
      flex="column"
      css={{
        alignItems: "center",
        gap: "$8",
        marginBottom: caption ? "$16" : 0,

        img: {
          borderRadius: "$16",
          "@bp2": {
            borderRadius: "$8",
          },
        },

        figcaption: {
          marginBottom: 0,
        },
      }}
    >
      <GatsbyImage
        alt={alt}
        image={getImage(image) as IGatsbyImageData}
        style={{}}
      />
      {caption && <figcaption>{caption}</figcaption>}
    </Box>
  )
}

export default Illustration
