import * as React from "react"
import Box from "../box"
import Text from "../text"

const IntroPoint = ({
  title,
  text,
  large = false,
  boxed = false,
  outlined = false,
  reverse = false,
  children,
}: {
  title?: string
  text: string
  large?: boolean
  boxed?: boolean
  outlined?: boolean
  reverse?: boolean
  children?: React.ReactNode
}) => {
  return (
    <>
      {boxed ? (
        <Box
          css={{
            // border: outlined ? "1px solid $background_tertiary" : "none",

            padding: outlined ? "$16" : 0,
            backgroundColor: outlined ? "$background_secondary" : "transparent",
            display: "flex",
            flexDirection: reverse ? "column-reverse" : "column",
            justifyContent: "center",
            gap: reverse ? "$4" : 0,

            borderRadius: "16px",
            "@bp2": {
              borderRadius: "$8",
            },
          }}
        >
          <Text
            size={reverse ? "medium" : "small"}
            css={{
              color: reverse ? "$text_secondary" : "$text_tertiary",
              marginBottom: 0,
            }}
          >
            {title && title}
            {children && children}
          </Text>
          <Text
            css={{
              fontFamily: "$sans",
              fontSize: large ? "$24" : "$16",
              marginBottom: 0,
              fontWeight: "$550",
            }}
          >
            {text}
          </Text>
        </Box>
      ) : (
        <Box
          grid="1to3"
          css={{
            alignItems: "baseline",
          }}
        >
          <Box as="p" grid="itemLeft">
            {title}
            {children && children}
          </Box>
          <Text size={large ? "large" : "medium"}>{text}</Text>
        </Box>
      )}
    </>
  )
}

export default IntroPoint
