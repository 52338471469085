import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"
import React from "react"
import Box from "../box"

export const Blob = ({ solid }: { solid?: boolean }) => {
  if (solid) {
    return (
      <svg
        viewBox="0 0 500 500"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="100%"
        height="100%"
        preserveAspectRatio="xMidYMid meet"
        id="blobSvg"
      >
        <defs>
          <clipPath id="clipping-path">
            <path>
              <animate
                attributeName="d"
                dur="20s"
                repeatCount="indefinite"
                calcMode="spline"
                keySplines="
                0.1 0.8 0.2 1;
                0.1 0.8 0.2 1;
                0.1 0.8 0.2 1"
                values="M454.504 367.676C395.6 447.946 321.117 485.946 231.054 481.677C140.991 477.407 73.764 437.272 29.3729 361.272C-15.0183 285.271 -10.7499 216.316 42.178 154.405C95.1059 92.4951 164.04 49.3713 248.981 25.0341C333.922 0.696905 404.137 31.6521 459.626 117.9C515.115 204.147 513.407 287.406 454.504 367.676Z;
                M442.344 288.926C408.662 348.232 370.127 313.294 277.502 319.5C184.877 325.706 80.1129 406.512 41.9993 341C3.8857 275.488 81.1705 193.099 121.5 130C161.829 66.9014 169.788 7.39606 268.174 0.844841C366.56 -5.70638 428.605 25.8429 454.31 95.4927C480.015 165.143 476.026 229.62 442.344 288.926Z;
                M456.004 385.514C403.496 456.38 328.774 493.161 231.838 495.857C134.901 498.553 69.3792 463.12 35.2719 389.558C1.16453 315.996 0.26697 241.278 32.5792 165.405C64.8914 89.5321 125.477 61.4168 214.335 81.059C303.194 100.701 378.813 138.638 441.194 194.868C503.574 251.099 508.511 314.648 456.004 385.514Z;
    M454.504 367.676C395.6 447.946 321.117 485.946 231.054 481.677C140.991 477.407 73.764 437.272 29.3729 361.272C-15.0183 285.271 -10.7499 216.316 42.178 154.405C95.1059 92.4951 164.04 49.3713 248.981 25.0341C333.922 0.696905 404.137 31.6521 459.626 117.9C515.115 204.147 513.407 287.406 454.504 367.676Z"
              ></animate>
            </path>
          </clipPath>

          <pattern
            id="pattern"
            x="0"
            y="0"
            width="100"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect x="0" y="0" width="100" height="20" fill="#F8B94B" />
            <path
              d="M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z"
              fill="white"
              fillOpacity="0.2"
            ></path>
          </pattern>
        </defs>

        <rect
          x="0"
          y="0"
          width="500"
          height="500"
          fill="url(#pattern)"
          clipPath="url(#clipping-path)"
        />
      </svg>
    )
  } else {
    return (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 500 360"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        preserveAspectRatio="xMidYMid meet"
        id="blobSvg"
      >
        <defs>
          <clipPath id="blob">
            <path>
              <animate
                attributeName="d"
                dur="15s"
                repeatCount="indefinite"
                keySplines="
                0.1 0.8 0.2 1;
                0.1 0.8 0.2 1;
                0.1 0.8 0.2 1"
                calcMode="spline"
                values="M446.741 273.401C377.141 318.701 298.321 346.707 210.284 357.42C122.246 368.133 61.6331 345.483 28.4461 289.47C-4.74101 233.457 -8.88939 172.547 16.0009 106.74C40.8912 40.9321 102.886 16.9047 201.987 34.6575C301.087 52.4102 384.055 85.3139 450.89 133.369C517.725 181.423 516.342 228.101 446.741 273.401Z;
              M452.306 260.412C391.268 312.617 321.987 341.845 244.464 348.094C166.94 354.344 99.4413 328.242 41.967 269.786C-15.5072 211.331 -13.9478 155.082 46.6452 101.038C107.238 46.9948 177.187 17.2157 256.493 11.701C335.799 6.18637 403.52 33.2081 459.658 92.7663C515.795 152.325 513.345 208.206 452.306 260.412Z;
              M444.593 251.883C387.1 309.54 314.401 336.834 226.496 333.768C138.59 330.701 72.9733 301.873 29.6454 247.283C-13.6825 192.694 -9.51635 143.164 42.1438 98.6955C93.804 54.2266 161.087 23.2516 243.993 5.77072C326.9 -11.7102 395.433 10.5243 449.593 72.4742C503.753 134.424 502.086 194.227 444.593 251.883Z;
      M446.741 273.401C377.141 318.701 298.321 346.707 210.284 357.42C122.246 368.133 61.6331 345.483 28.4461 289.47C-4.74101 233.457 -8.88939 172.547 16.0009 106.74C40.8912 40.9321 102.886 16.9047 201.987 34.6575C301.087 52.4102 384.055 85.3139 450.89 133.369C517.725 181.423 516.342 228.101 446.741 273.401Z"
              ></animate>
            </path>
          </clipPath>
        </defs>
        <g clip-path="url(#blob)" id="pattern2">
          <path
            d="M-148 4.58094C-140.264 6.45623 -133.791 6.24886 -125.936 4.97612C-111.376 2.61698 -97.0928 1.5979 -82.4011 0.727996C-56.4267 -0.809962 -31.3345 -0.0327406 -5.8033 5.23956C10.2989 8.56473 26.0357 13.3559 42.2762 16.041C56.5847 18.4067 71.0796 18.6639 85.5477 19.0706C111.964 19.8133 138.726 20.269 164.978 17.0289C193.164 13.5501 221.618 11.4174 249.874 8.43389C263.753 6.96846 277.472 4.5383 291.236 2.27576C310.233 -0.847034 329.267 -3.0606 348.47 -4.50806C391.958 -7.78607 437.478 -9.49699 479.767 2.67093C493.875 6.73038 507.98 10.957 522.149 14.7896C528.471 16.4996 535.005 17.3071 541.381 18.8072"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-148 284.581C-140.264 286.456 -133.791 286.249 -125.936 284.976C-111.376 282.617 -97.0928 281.598 -82.4011 280.728C-56.4267 279.19 -31.3345 279.967 -5.8033 285.24C10.2989 288.565 26.0357 293.356 42.2762 296.041C56.5847 298.407 71.0796 298.664 85.5477 299.071C111.964 299.813 138.726 300.269 164.978 297.029C193.164 293.55 221.618 291.417 249.874 288.434C263.753 286.968 277.472 284.538 291.236 282.276C310.233 279.153 329.267 276.939 348.47 275.492C391.958 272.214 437.478 270.503 479.767 282.671C493.875 286.73 507.98 290.957 522.149 294.79C528.471 296.5 535.005 297.307 541.381 298.807"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-147.719 65.5809C-139.983 67.4562 -133.51 67.2489 -125.655 65.9761C-111.095 63.617 -96.8116 62.5979 -82.1199 61.728C-56.1455 60.19 -31.0533 60.9673 -5.52205 66.2396C10.5801 69.5647 26.3169 74.3559 42.5574 77.041C56.866 79.4067 71.3609 79.6639 85.829 80.0706C112.246 80.8133 139.007 81.269 165.259 78.0289C193.445 74.5501 221.899 72.4174 250.155 69.4339C264.034 67.9685 277.753 65.5383 291.517 63.2758C310.514 60.153 329.548 57.9394 348.751 56.4919C392.24 53.2139 437.759 51.503 480.048 63.6709C494.156 67.7304 508.261 71.957 522.43 75.7896C528.752 77.4996 535.286 78.3071 541.662 79.8072"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-147.719 345.581C-139.983 347.456 -133.51 347.249 -125.655 345.976C-111.095 343.617 -96.8116 342.598 -82.1199 341.728C-56.1455 340.19 -31.0533 340.967 -5.52205 346.24C10.5801 349.565 26.3169 354.356 42.5574 357.041C56.866 359.407 71.3609 359.664 85.829 360.071C112.246 360.813 139.007 361.269 165.259 358.029C193.445 354.55 221.899 352.417 250.155 349.434C264.034 347.968 277.753 345.538 291.517 343.276C310.514 340.153 329.548 337.939 348.751 336.492C392.24 333.214 437.759 331.503 480.048 343.671C494.156 347.73 508.261 351.957 522.43 355.79C528.752 357.5 535.286 358.307 541.662 359.807"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-61 137.483C-53.2644 139.358 -46.7914 139.151 -38.9361 137.878C-24.3759 135.519 -10.0928 134.5 4.59885 133.63C30.5733 132.092 55.6655 132.869 81.1967 138.141C97.2989 141.467 113.036 146.258 129.276 148.943C143.585 151.309 158.08 151.566 172.548 151.973C198.964 152.715 225.726 153.171 251.978 149.931C280.164 146.452 308.618 144.319 336.874 141.336C350.753 139.87 364.472 137.44 378.236 135.178C397.233 132.055 416.267 129.841 435.47 128.394C478.958 125.116 524.478 123.405 566.767 135.573C580.875 139.632 594.98 143.859 609.149 147.691C615.471 149.402 622.005 150.209 628.381 151.709"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-105 207.483C-97.2644 209.358 -90.7914 209.151 -82.9361 207.878C-68.3759 205.519 -54.0928 204.5 -39.4011 203.63C-13.4267 202.092 11.6655 202.869 37.1967 208.141C53.2989 211.467 69.0357 216.258 85.2762 218.943C99.5847 221.309 114.08 221.566 128.548 221.973C154.964 222.715 181.726 223.171 207.978 219.931C236.164 216.452 264.618 214.319 292.874 211.336C306.753 209.87 320.472 207.44 334.236 205.178C353.233 202.055 372.267 199.841 391.47 198.394C434.958 195.116 480.478 193.405 522.767 205.573C536.875 209.632 550.98 213.859 565.149 217.691C571.471 219.402 578.005 220.209 584.381 221.709"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-136 246.581C-128.264 248.456 -121.791 248.249 -113.936 246.976C-99.3759 244.617 -85.0928 243.598 -70.4011 242.728C-44.4267 241.19 -19.3345 241.967 6.1967 247.24C22.2989 250.565 38.0357 255.356 54.2762 258.041C68.5847 260.407 83.0796 260.664 97.5477 261.071C123.964 261.813 150.726 262.269 176.978 259.029C205.164 255.55 233.618 253.417 261.874 250.434C275.753 248.968 289.472 246.538 303.236 244.276C322.233 241.153 341.267 238.939 360.47 237.492C403.958 234.214 449.478 232.503 491.767 244.671C505.875 248.73 519.98 252.957 534.149 256.79C540.471 258.5 547.005 259.307 553.381 260.807"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-122.82 20.1946C-104.681 20.1946 -86.809 19.3801 -68.7803 17.1649C-52.241 15.1328 -36.0293 11.7009 -19.6141 8.96508C9.47384 4.11709 38.3705 1.27644 67.851 0.765221C106.034 0.103091 144.391 0.356994 182.55 1.81902C221.344 3.30536 260.048 6.47266 298.83 7.97714C312.063 8.4905 325.306 8.28664 338.545 8.33939C354.053 8.40117 369.763 9.92547 385.241 8.6687C411.728 6.51804 437.745 -0.0352291 464.21 -1.96806C482.005 -3.26765 501.689 -3.4252 518.678 -9.77275C522.692 -11.2722 526.99 -11.6649 530.995 -13"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-122.82 300.195C-104.681 300.195 -86.809 299.38 -68.7803 297.165C-52.241 295.133 -36.0293 291.701 -19.6141 288.965C9.47384 284.117 38.3705 281.276 67.851 280.765C106.034 280.103 144.391 280.357 182.55 281.819C221.344 283.305 260.048 286.473 298.83 287.977C312.063 288.49 325.306 288.287 338.545 288.339C354.053 288.401 369.763 289.925 385.241 288.669C411.728 286.518 437.745 279.965 464.21 278.032C482.005 276.732 501.689 276.575 518.678 270.227C522.692 268.728 526.99 268.335 530.995 267"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-122.82 89.8841C-104.681 89.8841 -86.809 89.0695 -68.7803 86.8544C-52.241 84.8223 -36.0293 81.3904 -19.6141 78.6545C9.47384 73.8065 38.3705 70.9659 67.851 70.4547C106.034 69.7925 144.391 70.0464 182.55 71.5085C221.344 72.9948 260.048 76.1621 298.83 77.6666C312.063 78.1799 325.306 77.9761 338.545 78.0288C354.053 78.0906 369.763 79.6149 385.241 78.3582C411.728 76.2075 437.745 69.6542 464.21 67.7214C482.005 66.4218 501.689 66.2643 518.678 59.9167C522.692 58.4172 526.99 58.0245 530.995 56.6895"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-122.82 369.884C-104.681 369.884 -86.809 369.07 -68.7803 366.854C-52.241 364.822 -36.0293 361.39 -19.6141 358.655C9.47384 353.807 38.3705 350.966 67.851 350.455C106.034 349.793 144.391 350.046 182.55 351.508C221.344 352.995 260.048 356.162 298.83 357.667C312.063 358.18 325.306 357.976 338.545 358.029C354.053 358.091 369.763 359.615 385.241 358.358C411.728 356.207 437.745 349.654 464.21 347.721C482.005 346.422 501.689 346.264 518.678 339.917C522.692 338.417 526.99 338.025 530.995 336.689"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-61 164.195C-42.861 164.195 -24.9886 163.38 -6.95998 161.165C9.57928 159.133 25.791 155.701 42.2062 152.965C71.2942 148.117 100.191 145.276 129.671 144.765C167.854 144.103 206.212 144.357 244.371 145.819C283.164 147.305 321.868 150.473 360.65 151.977C373.884 152.49 387.126 152.287 400.365 152.339C415.873 152.401 431.584 153.925 447.062 152.669C473.549 150.518 499.566 143.965 526.031 142.032C543.825 140.732 563.51 140.575 580.499 134.227C584.512 132.728 588.81 132.335 592.815 131"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-122.82 229.263C-104.681 229.263 -86.809 228.448 -68.7803 226.233C-52.241 224.201 -36.0293 220.769 -19.6141 218.033C9.47384 213.185 38.3705 210.345 67.851 209.834C106.034 209.171 144.391 209.425 182.55 210.887C221.344 212.374 260.048 215.541 298.83 217.046C312.063 217.559 325.306 217.355 338.545 217.408C354.053 217.47 369.763 218.994 385.241 217.737C411.728 215.586 437.745 209.033 464.21 207.1C482.005 205.801 501.689 205.643 518.678 199.296C522.692 197.796 526.99 197.403 530.995 196.068"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-143.57 38.5704C-115.119 35.7871 -86.3791 37.1659 -57.8835 34.2894C-42.5974 32.7463 -27.4608 29.7558 -12.208 27.9007C5.19546 25.7841 22.652 25.2482 40.1526 24.6405C78.0664 23.3241 116.196 24.3442 154.127 24.3442C195.626 24.3442 237.113 24.8637 278.607 24.9369C314.574 25.0004 350.521 27.1243 386.49 27.0445C411.918 26.9881 437.114 22.2796 462.198 18.5812C482.229 15.6279 503.032 12.3477 522.495 6.56135C527.242 5.15008 532.224 3.24639 536.524 0.765463C537.584 0.153626 539.367 -0.706313 539.883 -1.7373"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-143.57 318.57C-115.119 315.787 -86.3791 317.166 -57.8835 314.289C-42.5974 312.746 -27.4608 309.756 -12.208 307.901C5.19546 305.784 22.652 305.248 40.1526 304.641C78.0664 303.324 116.196 304.344 154.127 304.344C195.626 304.344 237.113 304.864 278.607 304.937C314.574 305 350.521 307.124 386.49 307.045C411.918 306.988 437.114 302.28 462.198 298.581C482.229 295.628 503.032 292.348 522.495 286.561C527.242 285.15 532.224 283.246 536.524 280.765C537.584 280.154 539.367 279.294 539.883 278.263"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-143.57 108.26C-115.119 105.477 -86.3791 106.855 -57.8835 103.979C-42.5974 102.436 -27.4608 99.4453 -12.208 97.5902C5.19546 95.4736 22.652 94.9377 40.1526 94.33C78.0664 93.0135 116.196 94.0336 154.127 94.0336C195.626 94.0336 237.113 94.5532 278.607 94.6264C314.574 94.6898 350.521 96.8137 386.49 96.734C411.918 96.6776 437.114 91.969 462.198 88.2707C482.229 85.3174 503.032 82.0371 522.495 76.2508C527.242 74.8395 532.224 72.9358 536.524 70.4549C537.584 69.8431 539.367 68.9831 539.883 67.9521"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-143.57 388.26C-115.119 385.477 -86.3791 386.855 -57.8835 383.979C-42.5974 382.436 -27.4608 379.445 -12.208 377.59C5.19546 375.474 22.652 374.938 40.1526 374.33C78.0664 373.014 116.196 374.034 154.127 374.034C195.626 374.034 237.113 374.553 278.607 374.626C314.574 374.69 350.521 376.814 386.49 376.734C411.918 376.678 437.114 371.969 462.198 368.271C482.229 365.317 503.032 362.037 522.495 356.251C527.242 354.84 532.224 352.936 536.524 350.455C537.584 349.843 539.367 348.983 539.883 347.952"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-143.57 177.949C-115.119 175.166 -86.3791 176.545 -57.8835 173.668C-42.5974 172.125 -27.4608 169.135 -12.208 167.28C5.19546 165.163 22.652 164.627 40.1526 164.019C78.0664 162.703 116.196 163.723 154.127 163.723C195.626 163.723 237.113 164.243 278.607 164.316C314.574 164.379 350.521 166.503 386.49 166.423C411.918 166.367 437.114 161.658 462.198 157.96C482.229 155.007 503.032 151.727 522.495 145.94C527.242 144.529 532.224 142.625 536.524 140.144C537.584 139.533 539.367 138.673 539.883 137.642"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-143.57 247.639C-115.119 244.856 -86.3791 246.234 -57.8835 243.358C-42.5974 241.815 -27.4608 238.824 -12.208 236.969C5.19546 234.852 22.652 234.317 40.1526 233.709C78.0664 232.392 116.196 233.413 154.127 233.413C195.626 233.413 237.113 233.932 278.607 234.005C314.574 234.069 350.521 236.193 386.49 236.113C411.918 236.056 437.114 231.348 462.198 227.65C482.229 224.696 503.032 221.416 522.495 215.63C527.242 214.218 532.224 212.315 536.524 209.834C537.584 209.222 539.367 208.362 539.883 207.331"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-121.047 48.0543C-79.9218 45.3723 -38.7122 47.4166 2.37907 43.9709C23.3778 42.21 44.3635 39.2618 65.4422 38.6031C86.8427 37.9343 108.282 37.8015 129.691 38.0103C171.494 38.4182 213.123 43.1431 254.895 43.3122C286.771 43.4413 318.651 43.3122 350.527 43.3122C374.232 43.3122 397.809 43.5675 421.461 41.8303C448.806 39.8219 476.286 37.5682 503.394 33.367C516.324 31.3631 525.108 29.7142 534.909 21.0179C538.678 17.6736 542.435 14.2803 546.402 11.1714C547.442 10.3557 548.12 9.34715 549.365 8.93213"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-121.047 328.054C-79.9218 325.372 -38.7122 327.417 2.37907 323.971C23.3778 322.21 44.3635 319.262 65.4422 318.603C86.8427 317.934 108.282 317.801 129.691 318.01C171.494 318.418 213.123 323.143 254.895 323.312C286.771 323.441 318.651 323.312 350.527 323.312C374.232 323.312 397.809 323.567 421.461 321.83C448.806 319.822 476.286 317.568 503.394 313.367C516.324 311.363 525.108 309.714 534.909 301.018C538.678 297.674 542.435 294.28 546.402 291.171C547.442 290.356 548.12 289.347 549.365 288.932"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-69 112.122C-27.8749 109.44 13.3347 111.484 54.4259 108.039C75.4247 106.278 96.4104 103.33 117.489 102.671C138.89 102.002 160.328 101.869 181.738 102.078C223.541 102.486 265.17 107.211 306.942 107.38C338.818 107.509 370.697 107.38 402.574 107.38C426.279 107.38 449.856 107.635 473.508 105.898C500.853 103.89 528.333 101.636 555.44 97.4349C568.371 95.431 577.155 93.7821 586.956 85.0857C590.724 81.7415 594.482 78.3482 598.449 75.2393C599.489 74.4236 600.167 73.415 601.412 73"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-69 392.122C-27.8749 389.44 13.3347 391.484 54.4259 388.039C75.4247 386.278 96.4104 383.33 117.489 382.671C138.89 382.002 160.328 381.869 181.738 382.078C223.541 382.486 265.17 387.211 306.942 387.38C338.818 387.509 370.697 387.38 402.574 387.38C426.279 387.38 449.856 387.635 473.508 385.898C500.853 383.89 528.333 381.636 555.44 377.435C568.371 375.431 577.155 373.782 586.956 365.086C590.724 361.741 594.482 358.348 598.449 355.239C599.489 354.424 600.167 353.415 601.412 353"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-121.047 187.433C-79.9218 184.751 -38.7122 186.795 2.37907 183.35C23.3778 181.589 44.3635 178.641 65.4422 177.982C86.8427 177.313 108.282 177.18 129.691 177.389C171.494 177.797 213.123 182.522 254.895 182.691C286.771 182.82 318.651 182.691 350.527 182.691C374.232 182.691 397.809 182.946 421.461 181.209C448.806 179.201 476.286 176.947 503.394 172.746C516.324 170.742 525.108 169.093 534.909 160.397C538.678 157.053 542.435 153.659 546.402 150.55C547.442 149.735 548.12 148.726 549.365 148.311"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-121.047 257.123C-79.9218 254.441 -38.7122 256.485 2.37907 253.039C23.3778 251.278 44.3635 248.33 65.4422 247.671C86.8427 247.003 108.282 246.87 129.691 247.079C171.494 247.487 213.123 252.211 254.895 252.381C286.771 252.51 318.651 252.381 350.527 252.381C374.232 252.381 397.809 252.636 421.461 250.899C448.806 248.89 476.286 246.637 503.394 242.435C516.324 240.431 525.108 238.783 534.909 230.086C538.678 226.742 542.435 223.349 546.402 220.24C547.442 219.424 548.12 218.416 549.365 218"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M540.453 56.0682C506.288 56.2634 472.445 61.7836 438.235 61.864C412.996 61.9234 387.62 57.6006 362.493 55.4425C341.199 53.6136 320.049 50.8907 298.673 49.943C261.827 48.3094 224.701 49.5478 187.826 49.5478C156.876 49.5478 126.661 50.2269 96.0143 54.7509C67.7139 58.9286 40.3066 67.1606 12.0399 71.3482C-11.2777 74.8027 -34.6918 78.1275 -58.1694 80.2396C-86.3407 82.7741 -114.711 84.5207 -143 84.5207"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M540.453 336.068C506.288 336.263 472.445 341.784 438.235 341.864C412.996 341.923 387.62 337.601 362.493 335.442C341.199 333.614 320.049 330.891 298.673 329.943C261.827 328.309 224.701 329.548 187.826 329.548C156.876 329.548 126.661 330.227 96.0143 334.751C67.7139 338.929 40.3066 347.161 12.0399 351.348C-11.2777 354.803 -34.6918 358.127 -58.1694 360.24C-86.3407 362.774 -114.711 364.521 -143 364.521"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M538.453 127.068C504.288 127.263 470.445 132.784 436.235 132.864C410.996 132.923 385.62 128.601 360.493 126.442C339.199 124.614 318.049 121.891 296.673 120.943C259.827 119.309 222.701 120.548 185.826 120.548C154.876 120.548 124.661 121.227 94.0143 125.751C65.7139 129.929 38.3066 138.161 10.0399 142.348C-13.2777 145.803 -36.6918 149.127 -60.1694 151.24C-88.3407 153.774 -116.711 155.521 -145 155.521"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M531.586 185.062C497.421 185.258 463.577 190.778 429.368 190.858C404.129 190.918 378.753 186.595 353.626 184.437C332.332 182.608 311.182 179.885 289.805 178.937C252.959 177.304 215.834 178.542 178.959 178.542C148.009 178.542 117.794 179.221 87.1471 183.745C58.8467 187.923 31.4394 196.155 3.17267 200.342C-20.1449 203.797 -43.559 207.122 -67.0365 209.234C-95.2079 211.768 -123.579 213.515 -151.867 213.515"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M531.586 254.752C497.421 254.947 463.577 260.467 429.368 260.548C404.129 260.607 378.753 256.284 353.626 254.126C332.332 252.297 311.182 249.574 289.805 248.627C252.959 246.993 215.834 248.231 178.959 248.231C148.009 248.231 117.794 248.91 87.1471 253.435C58.8467 257.612 31.4394 265.844 3.17267 270.032C-20.1449 273.486 -43.559 276.811 -67.0365 278.923C-95.2079 281.458 -123.579 283.204 -151.867 283.204"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-129.344 54.5746C-113.639 56.1539 -97.9631 57.7508 -82.1863 58.4605C-60.9653 59.4152 -39.7137 59.4157 -18.4975 60.5022C13.7736 62.1549 45.8761 62.8733 78.1884 62.8733C112.807 62.8733 147.437 63.4896 182.053 63.9271C217.086 64.3698 252.233 66.0921 287.235 64.1905C313.904 62.7417 340.079 59.0789 366.468 55.1674C399.194 50.3165 431.984 47.5141 464.998 45.5515C489.385 44.1017 513.533 42.6334 537.973 42.7194C546 42.7477 554.468 42.5816 562.408 43.9049"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-129.344 334.575C-113.639 336.154 -97.9631 337.751 -82.1863 338.461C-60.9653 339.415 -39.7137 339.416 -18.4975 340.502C13.7736 342.155 45.8761 342.873 78.1884 342.873C112.807 342.873 147.437 343.49 182.053 343.927C217.086 344.37 252.233 346.092 287.235 344.191C313.904 342.742 340.079 339.079 366.468 335.167C399.194 330.316 431.984 327.514 464.998 325.552C489.385 324.102 513.533 322.633 537.973 322.719C546 322.748 554.468 322.582 562.408 323.905"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-129.344 124.264C-113.639 125.843 -97.9631 127.44 -82.1863 128.15C-60.9653 129.105 -39.7137 129.105 -18.4975 130.192C13.7736 131.844 45.8761 132.563 78.1884 132.563C112.807 132.563 147.437 133.179 182.053 133.617C217.086 134.059 252.233 135.782 287.235 133.88C313.904 132.431 340.079 128.768 366.468 124.857C399.194 120.006 431.984 117.204 464.998 115.241C489.385 113.791 513.533 112.323 537.973 112.409C546 112.437 554.468 112.271 562.408 113.594"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-13 184.859C2.70485 186.438 18.3806 188.035 34.1574 188.745C55.3785 189.699 76.63 189.7 97.8463 190.786C130.117 192.439 162.22 193.157 194.532 193.157C229.151 193.157 263.781 193.774 298.397 194.211C333.43 194.654 368.577 196.376 403.579 194.475C430.248 193.026 456.423 189.363 482.811 185.452C515.538 180.601 548.327 177.798 581.341 175.836C605.728 174.386 629.877 172.918 654.317 173.004C662.343 173.032 670.812 172.866 678.752 174.189"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
          <path
            d="M-129.344 263.643C-113.639 265.222 -97.9631 266.819 -82.1863 267.529C-60.9653 268.484 -39.7137 268.484 -18.4975 269.571C13.7736 271.223 45.8761 271.942 78.1884 271.942C112.807 271.942 147.437 272.558 182.053 272.995C217.086 273.438 252.233 275.16 287.235 273.259C313.904 271.81 340.079 268.147 366.468 264.236C399.194 259.385 431.984 256.582 464.998 254.62C489.385 253.17 513.533 251.702 537.973 251.788C546 251.816 554.468 251.65 562.408 252.973"
            stroke="#1E2830"
            fill="none"
            stroke-width="2"
          />
        </g>
      </svg>
    )
  }
}

const CoverProjectOverview = ({ cover }) => {
  return (
    <Box
      css={{
        width: "$full",
        borderRadius: "$16",
        overflow: "hidden",
        marginTop: "$16",
        display: "grid",
        maxHeight: "$512",

        gridAutoColumns: "minmax(0, 1fr)",
        gridAutoRows: "minmax(0, 1fr)",
      }}
    >
      <GatsbyImage
        image={getImage(cover) as IGatsbyImageData}
        style={{
          width: "100%",
          height: "100%",
          gridArea: "1 / 1 / 10 / 10",
        }}
        alt="Project overview page placed on simplified version of a Customer Journey Map"
      />
      <Box
        css={{
          gridArea: "2 / 4 / 10 / 10",
        }}
      >
        <Blob />
      </Box>
      <Box
        css={{
          gridArea: "1 / 2 / 8 / 6",
        }}
      >
        <Blob solid />
      </Box>
    </Box>
  )
}
export default CoverProjectOverview
