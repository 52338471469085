import React, { useEffect, useState } from "react"
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"

import Box from "../box"
import Illustration from "./illustration"
import { SectionContent } from "./structural"

const CardsGallery = ({ cards, largeImage }) => {
  const [active, setActive] = useState(0)
  const [scrolledLength, setScrolledLength] = useState(0)
  const [isScrollDisabled, setScrollDisabled] = useState(false)

  useEffect(() => {
    if (isScrollDisabled) {
      // Calculate the width of the scrollbar
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth
      document.body.style.overflow = "hidden"
      document.body.style.paddingRight = `${scrollbarWidth}px` // Add padding to prevent layout shifts
    } else {
      document.body.style.overflow = "auto"
      document.body.style.paddingRight = "0" // Reset padding
    }

    // Cleanup function to restore default styles
    return () => {
      document.body.style.overflow = "auto"
      document.body.style.paddingRight = "0"
    }
  }, [isScrollDisabled])

  const getTranslateValue = () => {
    switch (active) {
      case 0:
        return "translateY(-100%)"
      case 1:
        return "translateY(0)"
      case 2:
        return "translateY(100%)"
      default:
        return "translateY(0)"
    }
  }
  const getActiveRadius = () => {
    switch (active) {
      case 0:
        return "0 16px 16px 16px"
      case 1:
        return "16px"
      case 2:
        return "16px 16px 16px 0"
      default:
        return "16px"
    }
  }

  const scrollControl = e => {
    if (scrolledLength > 32 || scrolledLength < -32) {
      if (scrolledLength > 10 && active < 2) {
        setActive(active + 1)
      } else if (scrolledLength < -10 && active > 0) {
        setActive(active - 1)
      }
      setScrolledLength(0)
    } else {
      setScrolledLength(scrolledLength + e.deltaY)
    }
  }

  const translateActive = () => {
    let translationAmount = 100 * (active + 2)
    return `translateY(-${translationAmount}%)`
  }
  return (
    <>
      <Box
        css={{
          display: "none",
          gridTemplateColumns: "25% 75%",
          overscrollBehavior: "contain",

          "@bp2": {
            display: "block",
          },
        }}
      >
        <SectionContent>
          <Illustration
            image={largeImage}
            alt="Mobile and desktop versions of Pro cards"
            caption="Cards for 2 types of professionals"
          />
        </SectionContent>
      </Box>
      <Box
        css={{
          display: "grid",
          gridTemplateColumns: "25% 75%",
          paddingLeft: "$32",

          "@bp2": {
            display: "none",
          },
        }}
      >
        <Box
          css={{
            display: "grid",
            gridTemplateRows: "[item1] 1fr [item2] 1fr [item3] 1fr",
            gridTemplateColumns: "1fr",
            overscrollBehavior: "contain",
          }}
        >
          {cards.map((card, index) => {
            return (
              <Box
                as="button"
                onClick={() => setActive(index)}
                css={{
                  border:
                    index === active
                      ? "2px solid $background_secondary"
                      : "2px solid $background_primary",
                  outline: "none",
                  borderRadius: "16px",
                  padding: "$8",
                  margin: "$8",
                  backgroundColor: "transparent",
                  gridArea: `item${index + 1}`,
                  zIndex: "2",
                  overflow: "hidden",
                  transition: "$inOut1",
                  "&:hover":
                    index === active
                      ? {}
                      : {
                          cursor: "pointer",
                          border: "2px solid $background_tertiary",
                        },
                }}
              >
                <GatsbyImage
                  alt={"asc"}
                  image={getImage(card) as IGatsbyImageData}
                  style={{
                    borderRadius: "8px",
                  }}
                />
              </Box>
            )
          })}
          <Box
            css={{
              backgroundColor: "$background_secondary",
              gridArea: "item2",
              transform: getTranslateValue(),
              width: "$full",
              height: "$full",
              transition: "$inOut1",
              borderRadius: "24px 0 0 24px",
              zIndex: "1",
            }}
          />
        </Box>
        <Box
          onScroll={() => console.log("scrolled")}
          css={{
            borderRadius: getActiveRadius(),
            overflow: "hidden",
            width: "$full",
            height: "0",
            paddingTop: "100%",
            transition: "$inOut2",
          }}
        >
          {cards.map(card => {
            return (
              <Box
                onWheel={e => {
                  scrollControl(e)
                }}
                onMouseEnter={() => setScrollDisabled(true)}
                onMouseLeave={() => setScrollDisabled(false)}
                css={{
                  transform: translateActive(),
                  top: 0,
                  width: "$full",
                  height: "0",
                  paddingTop: "100%",
                  transition: "$inOut2",
                }}
              >
                <GatsbyImage
                  alt={"asc"}
                  image={getImage(card) as IGatsbyImageData}
                />
              </Box>
            )
          })}
        </Box>
      </Box>
    </>
  )
}

export default CardsGallery
