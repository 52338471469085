import { styled } from "@stitches/react"
import Box from "../box"
import Text from "../text"
import React, { ReactNode } from "react"
import { ArrowLeftIcon } from "../icons"
import { Link } from "gatsby"

export const Section = styled("section", {
  padding: "$48 0",
  marginBottom: "0",
  width: "$full",
})

export const SectionTitle = ({
  title,
  subtitle,
  sidebar,
}: {
  title: string
  subtitle?: string
  sidebar?: string
}) => {
  return (
    <Box
      grid="1to3"
      css={{
        width: "$full",
        marginBottom: "$24",
      }}
    >
      <Box as="p" grid="itemLeft">
        {sidebar}
      </Box>
      <Box
        flex={"column"}
        css={{
          h2: {
            fontSize: "$24",
            marginY: "0",
          },
        }}
      >
        <h2>{title}</h2>
        {subtitle && <Text css={{ fontStyle: "italic" }}>{subtitle}</Text>}
      </Box>
    </Box>
  )
}

export const SectionContent = ({
  sidebar,
  children,
}: {
  sidebar?: ReactNode
  children: ReactNode
}) => {
  return (
    <Box
      css={{
        width: "$full",
        display: "grid",
        gridTemplateColumns: "1fr 3fr",
        columnGap: "$32",

        "@bp2": {
          display: "flex",
          flexDirection: "column",
          gap: "0",
        },
      }}
    >
      <Box>{sidebar}</Box>
      <Text size="large">{children}</Text>
    </Box>
  )
}

export const BackLink = ({
  sidebar = false,
  displayOnMobile = true,
  displayOnDesktop = true,
}: {
  sidebar?: boolean
  displayOnMobile?: boolean
  displayOnDesktop?: boolean
}) => {
  return (
    <Link to="/resume">
      <Box
        flex="row"
        css={{
          alignItems: "center",
          display: displayOnDesktop ? "flex" : "none",
          transform: "translateX(-24px)",
          gap: "$8",
          "#back-icon": {
            transform: "translateX(0px)",
            transition: "$inOut1",
          },

          "&:hover": {
            "#back-icon": {
              transform: "translateX(-16px)",
            },
          },

          "@bp2": {
            display: displayOnMobile ? "flex" : "none",
            transform: "translateX(0)",
          },
        }}
      >
        <ArrowLeftIcon size={16} id="back-icon" />
        <Text
          css={{
            marginBottom: 0,
            fontFamily: "$sans",
            fontSize: sidebar ? "$12" : "$16",
            color: sidebar ? "$text_tertiary" : "$text_primary",
          }}
        >
          Return to resume
        </Text>
      </Box>
    </Link>
  )
}
